// Цвета
$color-black: #000000;
$color-onyx: #111111;
$color-dark-jungle: #222222;
$color-mine-shaft: #333333;
$color-tundora: #444444;
$color-davys-grey: #555555;
$color-ironside-gray: #666666;
$color-tapa-gray: #777777;
$color-taupe-gray: #888888;
$color-mountain-mist: #999999;
$color-dark-gray: #aaaaaa;
$color-gray: #bbbbbb;
$color-celeste: #cccccc;
$color-light-gray: #dddddd;
$color-eallery: #eeeeee;
$color-white: #ffffff;
$color-navy-blue: #0079c1;
$color-prussian-blue: #00426a;
$color-error: #e24831;
$color-carrot-orange: #f39318;

$color-supernova: #FCB53B;
$color-supernova-light: #FFCB05;
$color-pumpkin: #F57F29;
$color-cinnabar: #EE2D24;
$color-eclipse: #383838;
$color-eclipse2: #404040;
$color-suva-grey: #909090;
$color-suva-grey2: #919191;
$color-whisper: #ebebeb;
$color-almost-white: #f9f9f9;

$color-grey-opacity: rgba(145,145,145,0.7);
$color-black-opacity: rgba(0, 0, 0, 0.2);
$color-black-opacity2: rgba(0, 0, 0, 0.4);
$color-eclipse-opacity: rgba(56, 56, 56, 0.7);
$color-supernova-opacity: rgba(252, 181, 59, 0.2);
$color-suva-opacity: rgba(145,145,145, 0.25);

// КСО
$color-pumpkin-kco: #ED7817;
$color-harley-davidson-orange: #E32112;
$color-supernova-kco: #FAB82E;
$color-pumpkin-kco-2: #F57E27;
$color-white-smoke: #F2F2F2;
$color-whisper: #E6E6E6;
$color-nobel: #999999;
$color-night-rider: #333333;
$color-dim-gray: #666666;
$color-very-light-grey: #CCCCCC;
$color-whisper2: #EDEDED;

.color-navy-blue {
	color: $color-navy-blue;
}

.color-pumpkin {
	color: $color-pumpkin !important;
}

.color-cinnabar {
	color: $color-cinnabar !important;
}

.color-supernova-light {
	color: $color-supernova-light !important;
}